const MISSIONS_DATA = [
  {
    id: 1,
    location: 'HFTW Bulacan',
    pastor: 'Pastor Boyet Gonzales'
  },
  {
    id: 2,
    location: 'HFTW Cavite',
    pastor: 'Pastor Jun Rodriguez'
  },
  {
    id: 3,
    location: 'HFTW Las Piñas',
    pastor: 'Pastor Rafael Teodoro'
  },
  {
    id: 4,
    location: 'HFTW Butuan',
    pastor: 'Pastor Nolan Lloren'
  },
  {
    id: 5,
    location: 'HFTW Tagum',
    pastor: 'Pastor Nonie Gonzales'
  },
  {
    id: 6,
    location: 'HFTW Kuwait',
    pastor: 'Pastor Keith Mejia'
  },
  {
    id: 7,
    location: 'HFTW Bicol',
    pastor: 'Pastor Jun Rodriguez'
  }
];

export default MISSIONS_DATA;
