import React from 'react';
import LocationMap from './location-map.component';
import Footer from '../../footer/Footer';
import Header from '../../header/header.component';
import './location.styles.scss';

class Location extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <div>
        <Header />
        <h2 className='header-map'>Location and Service Times</h2>
        <div className='location-page'>
          <div className='map-container'>
            <LocationMap className='location-map' />
          </div>
          <span className='location-text'>
            <h3>Sunday Service</h3>
            <p>10:00am</p>
            <br />
            <h3>Address</h3>
            <p>1725 Beverly Blvd, Los Angeles, CA 90026</p>
          </span>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Location;
