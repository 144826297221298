import React from 'react';
import MINISTRY_DATA from '../../data/ministries.data';
import MinistryPreview from '../ministry-preview/ministry-preview.collection';
import './ministry-overview.styles.scss';

class MinistryOverview extends React.Component {
  constructor() {
    super();
    this.state = {
      ministries: MINISTRY_DATA
    };
  }

  render() {
    const ministries = this.state.ministries;
    return (
      <div className='ministry-overview'>
        {ministries.map(({ id, ...props }) => (
          <MinistryPreview key={id} {...props} />
        ))}
      </div>
    );
  }
}

export default MinistryOverview;
