import React from 'react';
import { withRouter } from 'react-router-dom';
import './LandingPage.css';
import * as video from '../../assets/banner.mp4';
import * as hope1 from '../../assets/hope1.jpg';
import * as hope2 from '../../assets/hope2.jpg';
import * as hope3 from '../../assets/hope3.jpg';
import * as worship from '../../assets/worship.jpg';
import * as wfacbanner from '../../assets/banner-wfac.png';
import { Icon } from 'react-icons-kit';
import { facebook2 } from 'react-icons-kit/icomoon/facebook2';
import { youtube } from 'react-icons-kit/icomoon/youtube';
import { instagram } from 'react-icons-kit/icomoon/instagram';

class LandingPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div id='top'>
          <section id='banner' data-video='../assets/banner'>
            <img src={worship} className='mobile-banner' alt='header-worship' />
            <video
              preload='auto'
              autoPlay={true}
              loop='loop'
              muted='muted'
              volume='0'
            >
              <source src={video} type='video/mp4' />
            </video>
            <div className='inner'>
              <header>
                <strong>
                  <h1 className='heading-primary-main'>HOPE FOR THE WORLD</h1>
                </strong>
                <h2
                  style={{ letterSpacing: '.5em' }}
                  className='heading-primary-sub'
                >
                  CHURCH AND MISSIONS
                </h2>
              </header>
            </div>
          </section>
          <div id='main'>
            <div className='wfac-banner'>
              <img
                onClick={() => this.props.history.push('/wfac')}
                src={wfacbanner}
                alt=''
              />
            </div>
            <div className='inner'>
              <div className='thumbnails'>
                <div className='box'>
                  <div
                    href='https://youtu.be/s6zR2T9vn2c'
                    className='image fit'
                  >
                    <img src={hope3} alt='' />
                  </div>
                  <div className='inner'>
                    <h3>Reaching Out</h3>
                    <p>
                      In obedience to the Great Commission of “Going out to all
                      the worlds...” (Matthew 28:19), Hope for the World reaches
                      out beyond the four corners of our church walls to the
                      four corners of the earth. We believe that we have a
                      mandate to stretch our boundaries and go out with God’s
                      long arm, especially to the lost, the least, and the last.
                    </p>
                  </div>
                </div>
                <div className='box'>
                  <div
                    href='https://youtu.be/s6zR2T9vn2c'
                    className='image fit'
                  >
                    <img src={hope1} alt='' />
                  </div>
                  <div className='inner'>
                    <h3>Touching Lives</h3>
                    <p>
                      The ultimate blessing in this life is the touch of the
                      Savior with the gift of salvation. We focus on lives,
                      hearts, and souls. Helping each one discover God’s plan in
                      their lives. Making an impact to touch their lives with
                      physical, emotional, and most importantly, spiritual
                      support.
                    </p>
                  </div>
                </div>
                <div className='box'>
                  <div href='' className='image fit'>
                    <img src={hope2} alt='' />
                  </div>
                  <div className='inner'>
                    <h3>Bringing Hope</h3>
                    <p>
                      HOPE. A blessing that means it is not done yet. That the
                      best is yet to come. That it is not the end of the road.
                      That life is worth living and fighting for. A hope based
                      on the victorious death and resurrection of our Lord Jesus
                      Christ. A solid Hope. A sure Hope. An Eternal Hope.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <footer id='footer'>
            <div className='inner'>
              <p>
                ' "For I know the plans I have for you,” declares the Lord,
                “plans to prosper you and not to harm you, plans to give you
                hope and a future." '
                <br />
                Jeremiah 29:11 (NIV)
              </p>
              <hr />
              <h4>Connect with us!</h4>
              <div className='row'>
                <div className='column'>
                  <span onClick={() => this.props.history.push('/location')}>
                    Location
                  </span>
                </div>
                <div className='column'>
                  <span onClick={() => this.props.history.push('/ministries')}>
                    Ministries
                  </span>
                </div>
                <div className='column'>
                  <span onClick={() => this.props.history.push('/missions')}>
                    Missions
                  </span>
                </div>
                <div
                  className='column'
                  onClick={() => this.props.history.push('/donate')}
                >
                  Donate
                </div>
              </div>
              <br />
              <div className='column' style={{ width: '100%' }}>
                <ul
                  className='icons'
                  style={{ display: 'table', margin: '0 auto' }}
                >
                  <li>
                    <a
                      href='https://www.facebook.com/hopefortheworldchurch/'
                      className='label'
                      alt='facebook'
                    >
                      <Icon size={50} icon={facebook2} />
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.youtube.com/channel/UCZUF-bCgenWr8L5u4SwbRGw'
                      className='label'
                    >
                      <Icon size={50} icon={youtube} />
                    </a>
                  </li>
                  <li>
                    <a
                      href='https://www.instagram.com/hopefortheworldchurch/'
                      className='label'
                    >
                      <Icon size={50} icon={instagram} />
                    </a>
                  </li>
                </ul>
              </div>
              <p className='copyright'>
                © 2019 - Hope for the World Church and Missions, Los Angeles
              </p>
            </div>
          </footer>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(LandingPage);
