import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import LandingPage from './components/landingPage/LandingPage.jsx';
import Location from './components/pages/locations/location.component';
import Ministries from './components/pages/ministries/ministries.component';
import PageNotFound from './components/pages/404/page-not-found.component';
// import Maintenance from './components/pages/maintenance/maintenance.component';
import Missions from './components/pages/missions/missions.component';
import Donate from './components/pages/donate/donate.component';
import WFAC from './components/pages/wfac/wfac.component';

class App extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path='/' component={LandingPage} />
        {/* <Route exact path='/home' component={LandingPage} /> */}
        <Route exact path='/location' component={Location} />
        <Route exact path='/ministries' component={Ministries} />
        <Route exact path='/donate' component={Donate} />
        <Route exact path='/missions' component={Missions} />
        <Route exact path='/wfac' component={WFAC} />
        <Route component={PageNotFound} />
      </Switch>
    );
  }
}

export default App;
