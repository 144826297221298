import React from 'react';
import Header from '../../header/header.component';
import Footer from '../../footer/Footer';
import MinistryOverview from '../../ministry-overview/ministry-overview.component';

class Ministries extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <div>
        <Header />
        <MinistryOverview />
        <Footer />
      </div>
    );
  }
}

export default Ministries;
