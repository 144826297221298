import React from 'react';
import Header from '../../header/header.component';
import Footer from '../../footer/Footer';
import MISSIONS_DATA from '../../../data/missions.data';
import './missions.styles.scss';
import MissionItems from '../../mission-items/mission-items.component';

class Mission extends React.Component {
  constructor() {
    super();
    this.state = {
      missions: MISSIONS_DATA
    };
  }

  render() {
    return (
      <React.Fragment>
        <Header />
        <div className='missions'>
          <h2>Mission Locations</h2>
          <div>
            {this.state.missions.map(mission => (
              <MissionItems key={mission.id} mission={mission} />
            ))}
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Mission;
