import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/logo1.svg';
import './header.styles.scss';

class Header extends React.Component {
  render() {
    return (
      <div className='header'>
        <Link className='logo-container' to='/'>
          <Logo className='logo' />
        </Link>
        <nav className='options'>
          <Link className='option first' to='/'>
            Home
          </Link>
          <Link className='option' to='/location'>
            Location
          </Link>
          <Link className='option' to='/ministries'>
            Ministries
          </Link>
          <Link className='option' to='/missions'>
            Missions
          </Link>
          <Link className='option' to='/donate'>
            Donate
          </Link>
        </nav>
      </div>
    );
  }
}

export default Header;
