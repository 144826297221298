import React from 'react';
import Header from '../../header/header.component';
import Footer from '../../footer/Footer';
import * as desktop from '../../../assets/desktop.png';
import * as phone from '../../../assets/phone.png';
import * as tithely from '../../../assets/tithely.png';
import './donate.styles.scss';

class Donate extends React.Component {
  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <>
        <Header />
        <div className='donate-header'>
          <h2>Why We Give </h2>
          <div className='donate-header-content'>
            "Give, and it will be given to you. A good measure, pressed down,
            shaken together and running over, will be poured into your lap. For
            with the measure you use, it will be measured to you.”
            <br />
            Luke 6:36 (NIV)
          </div>
          <br />
          <h2>How We Give</h2>
          <div className='donate-container'>
            <div className='donate-icons'>
              <div className='donate-inner'>
                <img src={desktop} alt='desktop' className='donate-image' />
                <div className='donate-text'>
                  <h3>ONLINE</h3>
                  <p className='donate-text-inner'>
                    Make an online contribution by clicking{' '}
                    <a
                      href='https://tithe.ly/give?c=847793'
                      className='donate-link'
                    >
                      HERE
                    </a>
                  </p>
                </div>
              </div>
              <div className='donate-inner'>
                <img src={phone} alt='phone' className='donate-image' />
                <div className='donate-text'>
                  <h3>TEXT</h3>
                  <p className='donate-text-inner'>
                    Text 'GIVE' to 949-393-4273
                  </p>
                </div>
              </div>
              <div className='donate-inner'>
                <img src={tithely} alt='tithley' className='donate-image' />
                <div className='donate-text'>
                  <h3>TITHE.LY APP</h3>
                  <p className='donate-text-inner'>
                    Download the app{' '}
                    <a
                      href='https://get.tithe.ly/download-tithely-app'
                      className='donate-link'
                    >
                      HERE
                    </a>{' '}
                    and create an account
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default Donate;
