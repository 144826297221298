const MINISTRY_DATA = [
  {
    id: 1,
    ministries: [
      {
        id: 1,
        title: 'Multimedia',
        description:
          'We strive to carry the Gospel of Jesus Christ to our visually-driven, ever-changing world through technology and media. We believe that electronic media is a blessing and a wonderful tool to touch lives everywhere we go. We do not only provide and equip our church with technical support for the worship services, but we also want everyone to be able to access and watch our various events and sermons wherever in the world they are.',
        imageUrl: 'https://i.ibb.co/N9VPwRk/multimedia.png'
      },
      {
        id: 2,
        title: 'Worship',
        description:
          'We are a group of people from varying backgrounds who share a common love for God and music. We are blessed with the opportunity to use our God-given musical talents to express our praise and worship to the One who deserves it all. With our voices and instruments, we let out a loud cry to proclaim the greatest name of them all — Jesus Christ!',
        imageUrl: 'https://i.ibb.co/zJLStVb/worhsip.png'
      },
      {
        id: 3,
        title: 'Connect',
        description:
          'Our goal is to make you smile. We want to make sure every person who enters the sanctuary feels welcomed into God’s family—may it be a handshake, a hug, or helping anyone to their seats, we want everyone to feel that they belong. We help set the tone and assist in the smooth operation of the worship celebration to allow everyone to just simply enjoy and bask in God’s presence. ',
        imageUrl: 'https://i.ibb.co/gWqGftt/connect.png'
      },
      {
        id: 4,
        title: 'Production',
        description:
          'We believe in the value of creating the overall atmosphere of worship to help people further experience the presence of God. Our job is to worry about and take care of everything else that happens around the church, so you don’t have to. Organization is key, and we are determined to make everything flow smoothly—from planning to execution and coordination, we ensure that we see it all through.',
        imageUrl: 'https://i.ibb.co/qsfzmVh/prod.png'
      }
    ]
  },
  {
    id: 2,
    ministries: [
      {
        id: 5,
        title: 'Manna',
        description:
          'We demonstrate our love for Jesus by feeding His flock. Our people, out of their benevolence, share their blessings and create the festive mood, while everyone breaks bread and fellowships with one another. We thrive in happy tummies and serving delicious food to God’s children. ',
        imageUrl: 'https://i.ibb.co/jJvb9bN/manna-2.png'
      },
      {
        id: 6,
        title: 'Logistics',
        description:
          'We are a group of people who are passionate about keeping everything in check and in proper order. Our duty is to keep our church organized and well-kept. It is our joy to serve through keeping our inventory in check and maintaining our sanctuary and parking areas in proper order. We strive to keep our place of worship in excellent condition because we serve an excellent God. ',
        imageUrl: 'https://i.ibb.co/s9mt2MK/logistics.png'
      },
      {
        id: 7,
        title: 'Prayer',
        description:
          'We believe that prayer is the backbone of the church. We are a team of people who devote ourselves to praying, fasting, and interceding for our world, our spiritual family and everything else in between. As we cover every single day, know that someone is always thinking of you in their prayers. ',
        verse:
          'Matthew 18:19 "Again I say to you that if two of you agree on earth concerning anything that they ask, it will be done for them by My Father in heaven."',
        imageUrl: 'https://i.ibb.co/PMvshwL/prayer.png'
      },
      {
        id: 8,
        title: 'Hopelets',
        description:
          'We are geared to help children grow in the knowledge of God and teach them the importance of obeying His Word even at a young age. Every lesson being shared to them is Bible-based as we aim to develop the children’s love for God and for people. We work hard to hone their God-given talents and give them the opportunity to express these in worship to Him.',
        imageUrl: 'https://i.ibb.co/MfmzXhQ/hopelets.png'
      }
    ]
  },
  {
    id: 3,
    ministries: [
      {
        id: 9,
        title: 'Creative',
        description:
          'We are a team full of inspiration and stories to tell. We are blessed with a plethora of talented people driven by creative thoughts, visions, and ideas swirling around our heads. Through our every art form, we are endlessly blown away by our Master Artist. Galvanized and enthused by God’s joy and love exhibited in this exquisitely crafted world around us, we create.',
        verse: 'Exodus 35:31-32',
        imageUrl: 'https://i.ibb.co/x3YrhHm/creative.png'
      },
      {
        id: 10,
        title: 'Tech',
        description:
          'We work behind-the-scenes to provide technical support for the Sunday celebrations. We worship God in the shadows so everything else can be brightly lit or the sounds can be heard and felt. It is our duty to ensure that every Sunday service is of excellent quality in every aspect because we serve an excellent God.',
        imageUrl: 'https://i.ibb.co/NW4cCNX/techteam-smol.png'
      }
    ]
  }
];

export default MINISTRY_DATA;
