import React from 'react';

class MissionItems extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className='mission-item'>
          <span className='location'>{this.props.mission.location}</span>
          <div>
            <p>
              <span style={{ fontSize: '90%' }}>Supervised by</span>{' '}
              {this.props.mission.pastor}
            </p>
          </div>
          <br />
        </div>
      </React.Fragment>
    );
  }
}

export default MissionItems;
