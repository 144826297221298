import React from 'react';
import CustomButon from '../custom-button/custom-button.component';
import MinistryDescription from '../ministry-description/ministry-description.component';
import ReactModal from 'react-modal';
import './ministry-item.styles.scss';

class MinistryItem extends React.Component {
  state = {
    modalState: false
  };

  openModal = () => {
    this.setState({
      modalState: true
    });
  };

  closeModal = () => {
    this.setState({
      modalState: false
    });
  };

  render() {
    return (
      <React.Fragment>
        <div className='ministry-item'>
          <div className='ministry-header'>
            <span className='title'>{this.props.ministry.title}</span>
          </div>
          <div
            className='image'
            style={{
              backgroundImage: `url(${this.props.ministry.imageUrl})`
            }}
          />
          <CustomButon onClick={this.openModal}>Learn More</CustomButon>
        </div>
        <ReactModal
          isOpen={this.state.modalState}
          onRequestClose={this.closeModal}
          ariaHideApp={false}
          className='modal'
        >
          <MinistryDescription ministry={this.props.ministry} />
        </ReactModal>
      </React.Fragment>
    );
  }
}

export default MinistryItem;
