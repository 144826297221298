import React from 'react';
import './page-not-found.styles.scss';
import * as img404 from '../../../assets/404.png';

class PageNotFound extends React.Component {
  render() {
    return (
      <div className='page-not-found'>
        <div className='container'>
          <img className='img' src={img404} alt='404' />
          <div className='text-box'>404 - Page Not Found</div>
        </div>
        <div className='text-box-2'>
          Just like you, the gopher is lost somewhere on this site.
          <hr />
          <span
            className='home-link'
            onClick={() => this.props.history.push('/')}
          >
            Go Back Home
          </span>
        </div>
      </div>
    );
  }
}

export default PageNotFound;
