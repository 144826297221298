import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps';

const googleKey = process.env.REACT_APP_GOOGLE_API_KEY;

class LocationMap extends React.Component {
  render() {
    const GoogleMapMain = withScriptjs(
      withGoogleMap(props => (
        <GoogleMap
          defaultCenter={{
            lat: 34.0653484,
            lng: -118.2664451
          }}
          defaultZoom={16}
        >
          {props.isMarkerShown && (
            <Marker
              position={{
                lat: 34.0653484,
                lng: -118.2664451
              }}
              text='Hope for the World'
            />
          )}
        </GoogleMap>
      ))
    );
    return (
      <GoogleMapMain
        googleMapURL={
          'https://maps.googleapis.com/maps/api/js?key=' +
          googleKey +
          '&v=3.exp&libraries=geometry,drawing,places'
        }
        loadingElement={<div style={{ height: '100%' }} />}
        isMarkerShown={true}
        containerElement={<div style={{ height: '25rem', width: '30vw' }} />}
        mapElement={<div style={{ height: '100%' }} />}
      />
    );
  }
}

export default LocationMap;
