import React from 'react';
import './ministry-description.styles.scss';

class MinistryDescription extends React.Component {
  render() {
    return (
      <div className='ministry-description row'>
        <div
          className='image column'
          style={{
            backgroundImage: `url(${this.props.ministry.imageUrl})`
          }}
        />
        <div className='ministry-text column'>
          <div className='title'>
            {this.props.ministry.title.toUpperCase()} TEAM
          </div>
          <hr
            style={{ color: 'black', backgroundColor: 'black', height: '1px' }}
          />
          <div className='description'>{this.props.ministry.description}</div>
        </div>
      </div>
    );
  }
}

export default MinistryDescription;
