import React from 'react';
import Header from '../../header/header.component';
import Footer from '../../footer/Footer';
import Youtube from '../../youtube/youtube.component';
import * as wfacBannerMain from '../../../assets/wfac-banner-main.png';
import * as paintNight from '../../../assets/paint.png';
import * as pastries from '../../../assets/pastries.png';
import './wfac.styles.scss';

class WFAC extends React.Component {
  state = {
    url: '_omLbuWEqRA'
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <>
        <Header />
        <div className='youtube-section'>
          <Youtube url={this.state.url} />
        </div>
        <div className='pictures'>
          <img src={wfacBannerMain} alt='wfac-main' />
        </div>
        <div className='advert'>
          <h2>SUPPORT OUR FUNDRAISING PROJECTS</h2>
          <img src={paintNight} alt='paint night' />
          <img src={pastries} alt='pastries' />
        </div>
        <Footer />
      </>
    );
  }
}

export default WFAC;
