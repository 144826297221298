import React from 'react';
import MinistryItem from '../ministry-items/ministry-item.component';
import './ministry-preview.styles.scss';

const MinistryPreview = ({ ministries }) => (
  <div className='ministry-preview'>
    {/* <h1 className='title'>{title.toUpperCase()}</h1> */}
    <div className='preview'>
      {ministries.map(ministry => (
        <MinistryItem key={ministry.id} ministry={ministry} />
      ))}
    </div>
  </div>
);

export default MinistryPreview;
