import React from 'react';
import { Icon } from 'react-icons-kit';
import { facebook2 } from 'react-icons-kit/icomoon/facebook2';
import { youtube } from 'react-icons-kit/icomoon/youtube';
import { instagram } from 'react-icons-kit/icomoon/instagram';
// import { mail2 } from 'react-icons-kit/icomoon/mail2';
import './footer.styles.scss';

class Footer extends React.Component {
  render() {
    return (
      <footer id='footer'>
        <div className='inner'>
          <h2>Hope for the World Church and Missions</h2>
          <h4 style={{ marginTop: '-2rem' }}>Los Angeles, CA</h4>
          <p>
            "For I know the plans I have for you,” declares the LORD, “plans to
            prosper you and not to harm you, plans to give you hope and a
            future." - Jeremiah 24:11 (NIV)
          </p>
          <h4>Connect with us!</h4>
          <ul className='icons'>
            <li>
              <a
                href='https://www.facebook.com/hopefortheworldchurch/'
                className='label'
                alt='facebook'
              >
                <Icon size={50} icon={facebook2} />
              </a>
            </li>
            <li>
              <a
                href='https://www.youtube.com/channel/UCZUF-bCgenWr8L5u4SwbRGw'
                className='label'
              >
                <Icon size={50} icon={youtube} />
              </a>
            </li>
            <li>
              <a
                href='https://www.instagram.com/hopefortheworldchurch/'
                className='label'
              >
                <Icon size={50} icon={instagram} />
              </a>
            </li>
            {/* <li>
              <span className='label'>
                <Icon size={50} icon={mail2} />
              </span>
            </li> */}
          </ul>
          <p className='copyright'>
            © 2019 - Hope for the World Church and Missions, Los Angeles
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
