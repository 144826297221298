import React from 'react';

class Youtube extends React.Component {
  render() {
    return (
      <div
        className='video'
        style={{
          position: 'relative',
          paddingBottom: '56.25%',
          paddingTop: 25,
          height: 0
        }}
      >
        <iframe
          title='thing'
          style={{
            position: 'absolute',
            top: 0,
            left: '25%',
            width: '50%',
            height: '50%'
          }}
          src={`https://www.youtube.com/embed/${this.props.url}`}
          frameBorder='0'
        />
      </div>
    );
  }
}

export default Youtube;
